<template>
  <div class="page" style="box-sizing: border-box; margin-top: 46px;">
    <van-nav-bar title="用户柜子" fixed left-arrow @click-left="$router.back()"></van-nav-bar>
    <van-tree-select
        height="calc(100% - 50px)"
        :items="items"
        :active-id.sync="activeIds"
        :main-active-index.sync="activeIndex"
        @click-nav="clickRole"
    />

    <div style="position: absolute;bottom: env(safe-area-inset-bottom); z-index: 100;width: 100%;display: flex;justify-content: center">
      <van-button round type="primary" :disabled="!enableSave" @click="clickSave">保存修改</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "rolePermission",
  data() {
    return {
      items: [],
      activeIds: [],
      activeIndex: 0,

      bindList: [],
      cabinetList: []
    };
  },
  created() {
    this.initData()
  },
  computed: {
    enableSave() {
      if (this.bindList.length <= 0) return false
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      let old = this.bindList[this.activeIndex].cabinetIdList.sort().join()
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      let changed = this.activeIds.sort().join()
      return old !== changed
    }
  },
  methods: {

    async loadAllCabinet() {
      let res = await this.$http.get('cabinet')
      if (res.code === 200) {
        this.cabinetList = res.data
      }
    },
    async loadBindList() {
      let res = await this.$http.get('userCabinet/bind')
      if (res.code === 200) {
        this.bindList = res.data
      }
    },
    initData() {
      let load = this.$toast.loading()
      Promise.all([this.loadAllCabinet(), this.loadBindList()]).then(() => {
        load.clear()
        this.activeIds = this.bindList[0].cabinetIdList.map(it => it)
        let items = []
        this.bindList.forEach(it => {
          let item = {id: it.userId, text: it.username}
          item.children = this.cabinetList.map(p => { return {id: p.deviceId, text: p.deviceName} })
          items.push(item)
        })
        this.items = items
      })
    },
    clickRole(index) {
      console.log(index)
      this.activeIds = this.bindList[index].cabinetIdList
    },
    async clickSave() {
      let res = await this.$http.put('userCabinet/bind', {userId: this.items[this.activeIndex].id, cabinetList: this.activeIds})
      if (res.code === 200) {
        this.$toast.success('更新成功')
        this.bindList[this.activeIndex].cabinetIdList = this.activeIds
      } else {
        this.$toast.fail(res.msg)
      }
    },

    async loadRolePermission(role) {
      let data
      if (role.code === 'admin') {
        data = this.permissionList
      } else {
        let res = await this.$http.get('rolePermission', {params: {roleId: role.id}})
        if (res.code === 200) {
          data = res.data
        }
      }
      this.rolePermissionList = data
      this.activeIds = data.map(it => it.id)
    }
  }
}
</script>

<style scoped>
/deep/ .van-tree-select__item:last-child {
  margin-bottom: 100px;
}
</style>
